<template>
  <smartx-oms-trade-desk-wc
    :environment="env"
    :auth-token="authToken"
    :parent-env="clientEnv"
  />
</template>

<script>
import '@hedgeco/smartx-oms-trade-desk-wc'

export default {
  data () {
    return {
      authToken: '',
      // can pass this as parentEnv prop but not needed -- empty prop should be treated the same
      clientEnv: '1abc4124fa0e'
    }
  },
  computed: {
    env () {
      return this.$store.getters.appEnv.env_name
    }
  },
  created () {
    this.authToken = localStorage.getItem('token')
  }
}
</script>
