<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <smx-trade-generation
          :auth-token="authToken"
          :environment="envLocal"
          :parent-environment="clientEnvironment"
          @click:acr-row="onClick"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'primary'
    }
  },
  data () {
    return {
      env: '',
      clientEnvironment: '',
      authToken: ''
    }
  },
  created () {
    this.env = process.env.VUE_APP_ENV

    // Magic number that depicts parent env for the trade generation ui component
    this.clientEnvironment = '1abc4124fa0e'

    this.authToken = `Bearer ${localStorage.getItem('token')}`
  },
  computed: {
    envLocal () {
      return this.env === 'production' ? 'live' : this.env
    }
  },
  methods: {
    onClick ({ detail }) {
      const [allocation] = detail
      if (allocation?.allocationId) {
        this.$router.push(`/allocations/${allocation.allocationId}/overview`)
      }
    }
  }
}
</script>
