import Vue from 'vue'
import VueRouter from 'vue-router'

import NotFound from '@/views/NotFound.vue'
import Unauthorized from '@/views/Unauthorized.vue'

import {
  ClientBadSymbols,
  ClientBuildEvents,
  ClientEquityTrades,
  ClientMutualFundTrades,
  ClientTradeMonitorWrapper,
  ClientTradeMonitorBlocks,
  ClientTradeMonitorAllocations,
  ClientTradingContainer,
  ClientApmTradeManager
} from '@/components/trade-dashboard'

import { TradeGenerationContainer } from '@/components/trade-generation'
import { TradeDeskContainer } from '@/components/trade-desk'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'trade-dashboard',
    name: 'Home'
  },
  {
    path: '/trade-dashboard',
    component: ClientTradingContainer,
    props: (route) => ({
      name: route.name,
      color: 'primary',
      padding: 'pt-0 px-1 pb-2',
      links: [
        { icon: 'trending_up', name: 'Trade Desk', href: '#/trade-dashboard/trade-desk-wc' },
        { icon: 'trending_down', name: 'Equities', href: '#/trade-dashboard/equity-trades' },
        { icon: 'pie_chart', name: 'Mutual Funds', href: '#/trade-dashboard/mutual-fund-trades' },
        { icon: 'pageview', name: 'Trade Monitor', href: '#/trade-dashboard/trade-monitor' },
        { icon: 'warning', name: 'Symbol Exclusions', href: '#/trade-dashboard/symbol-exclusions' },
        { icon: 'trending_down', name: 'Trade Generation', href: '#/trade-dashboard/smartx-trade-generation-ui' },
        { icon: 'pie-chart', name: 'APM Trades', href: '#/trade-dashboard/apm-trade-manager' },
        { icon: 'event', name: 'Build Events', href: '#/trade-dashboard/build-events' }
      ]
    }),
    children: [
      {
        path: '',
        alias: 'trade-desk-wc',
        name: 'Trade Desk',
        component: TradeDeskContainer,
        meta: { requiresAuth: true, role: 'Trader' }
      },
      {
        path: 'equity-trades',
        name: 'Equity Trades',
        component: ClientEquityTrades,
        meta: { requiresAuth: true, role: 'Trader' }
      },
      {
        path: 'mutual-fund-trades',
        name: 'Mutual Fund Trades',
        component: ClientMutualFundTrades,
        meta: { requiresAuth: true, role: 'Trader' },
        params: true,
        props: (route) => ({ id: route.params.guid, scope: 'trade-builder' })
      },
      {
        path: 'symbol-exclusions',
        name: 'Symbol Exclusions',
        component: ClientBadSymbols,
        meta: { requiresAuth: true, role: 'Trader' }
      },
      {
        path: 'trade-monitor',
        name: 'Trade Monitor',
        component: ClientTradeMonitorWrapper,
        meta: { requiresAuth: true, role: 'Trader' },
        redirect: { name: 'Trade Monitor Blocks' },
        children: [
          {
            path: 'blocks',
            name: 'Trade Monitor Blocks',
            component: ClientTradeMonitorBlocks
          },
          {
            path: 'allocations',
            name: 'Trade Monitor Allocations',
            component: ClientTradeMonitorAllocations,
            props: (route) => ({ requestId: route.query.requestId })
          }
        ]
      },
      {
        path: 'smartx-trade-generation-ui',
        name: 'Trade Generation',
        component: TradeGenerationContainer,
        meta: { requiresAuth: false, role: 'Trader' }
      },
      {
        path: 'apm-trade-manager',
        name: 'APM Trades',
        component: ClientApmTradeManager,
        meta: { requiresAuth: true, role: 'Trader' },
        params: true,
        props: (route) => ({ id: route.params.guid, scope: 'trade-builder' })
      },
      {
        path: 'build-events',
        name: 'Build Events',
        component: ClientBuildEvents,
        meta: { requiresAuth: true, role: 'Trader' }
      }
    ]
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: Unauthorized,
    meta: { requiresAuth: false, role: 'Trader' }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Page Not Found',
    component: NotFound,
    meta: { requiresAuth: false, role: 'Trader' }
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
